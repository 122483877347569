import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';
import config from './config';


// CONFIGS
// var post_url = 'https://www.reallygreatrate.com/formdov.php';
// var post_url = '//' + config.endpoint + '/api/solar/';
var protocol = document.location.protocol;
var post_url = protocol + '//' + config.endpoint + '/formdov.php';


    
// If user leaves mid page
$(window).on('beforeunload', function() {
    const url = window.location.href;
    if(!url.toLowerCase().includes('terms-of-use.html') && !url.toLowerCase().includes('privacy-policy.html') && !url.toLowerCase().includes('partners-list.html')) {
        setTimeout(function () {
            setTimeout(function () {
                showPopUp();
                $('.popup')
                    .removeClass('hide');
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();
                var exit_strategy = true;
                getClickWall(exit_strategy);
            }, 3000);
        }, 1);
        return 'Would you like to see rates from our top lenders?';
    }
});


/*
   HELPERS
 */
/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email)
        .match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}
/*
 * Sets hash value
 */
function setHash(hash) {
    // if(history.pushState) {
    //     history.pushState(null, null, hash);
    // }
    // else {
    //     location.hash = hash;
    // }
}
/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}



var get_params = function (search_string) {
    var parse = function (params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1)
            .join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1)
        .split('&'));
};
/*
 USER IP
 */

$.ajax({
    url: '//www.reallygreatrate.com/api/user/ip_address/',
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip')
            .val(response.ip_address);
    }
    
});
        
    
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe')
    .each(function () { // the containers for all your galleries
        $(this)
            .magnificPopup({
                type: 'iframe',
                mainClass: 'iframe-popup'
            });
    });

$('.mfp-inline')
    .each(function () { // the containers for all your galleries
        $('.mfp-inline')
            .magnificPopup({
                type: 'inline'
            });
    });

/*
    POPUP
*/
function showPopUp() {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a')
            .first()
            .focus();
    }, 301);
    $('html')
        .addClass('popup-open');
    $('.popup')
        .fadeIn();
    $(window)
        .scrollTop(0);
}

function getClickWall(exit_strategy = false) {
    var confirmation_div = $('#confirmation');
    var affcid, key;


    // Generate Random Number to serve different click listings
    var randomized_num = getRandomNum(100); // random 1-100
    //console.log(randomized_num);
    // var renuantStates = ['ME', 'AK', 'MN', 'WA', 'UT', 'OR', 'MT', 'CT', 'MS', 'NH', 'NM'];
    if (exit_strategy == true) {
        var deleteCredit = "";
        $('[name=field_6]').val(deleteCredit);
    }
    var rt_data = {
        zip: $('input[name="zip"]')
            .val(),
        state: $('input[name="state"]')
            .val(),
        credit_status: $('input[name="field_6"]')
            .val(),
        loan_amt: $('input[name="field_3"]')
            .val(),
        military: $('input[name="field_28"]')
            .val(),
        property_value: $('input[name="field_2"]')
            .val(),
        property_type: $('input[name="field_1"]')
            .val(),
        publisher_id: $('input[name="publisher_id"]')
            .val(),
        subid: $('input[name="subid"]')
            .val(),
    };

    var clickwallPercentage;
    
    $.ajax({
        async: false,
        url: "//www.reallygreatrate.com/api/clickwall_percentage/",
        type: "GET",
        dataType: 'json',
        success: function (response) {
            clickwallPercentage = response.number;
        }
                
    });
    // if random number is less than below serve the clicks net clickwall; otherwise serve the renuant clickwall
    console.log(clickwallPercentage);
    if (randomized_num <= clickwallPercentage) {

        /*
        CLICKS NET CLICK WALL
        */

        console.log('cn');
        //console.log($scope.data);

        if (exit_strategy == true) {
            affcid = "1103401";
            key = "FEg9_CYDZWw1";
            $('#confirmation_qs_text')
                .empty()
                .text("Click on a lender below for your free quote!");

        } else {
            affcid = "1058706";
            key = "56pg95fEw2k1";
        }
        var zip = $('input[name="zip"]')
            .val();

        //     Parameter for Credit Rating
        var clicksnet_credit_rating = 'Good (620 - 719)';
        //     Parameter for Loan Amount
        var clicksnet_loan_range = '$150,001 - $160,000';
        //     Parameter for Loan Type and Term
        var clicksnet_loan_type_term = 'Refinance - FHA Rate - 30 Year';
        //     Subids are used to track conversions and traffic
        var subid1 = $('input[name="publisher_id"]')
            .val();
        //     Subids are used to track conversions and traffic
        var subid2 = $('input[name="rcid"]')
            .val();

        //Optional preview parameter
        // var creative_id = clicksNetGetQueryStringParam('preview');

        //Optional Query Parameters:
        //     showHeader=[true||false] -> will show or hide header (Default: true)
        //     showWidget=[true||false] -> will show or hide the subWidget filter bar (Default: true)
        //     showFooter=[true||false] -> will show or hide footer (Default: true)
        var showHeader = false;
        var showWidget = false;
        var showFooter = false;

        //baseQuery 
        //Purpose: base script query
        var baseQuery = clicksNetGetProtocol() + 'cdn.fcmrktplace.com/listing/?affcamid=' +
            affcid + '&key=' + key +
            '&subid1=' + subid1 + '&subid2=' + subid2;

        //clicksnet_script_src 
        //Purpose: script to fetch listings 
        //Filter Query Parameters:
        //     clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
        //     clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
        //     clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
        var clicksnet_script_src = baseQuery + '&zip=' + zip +
            '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range +
            '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader +
            '&showWidget=' + showWidget + '&showFooter=' + showFooter;

        var fetchListings = function () {
            var clicksnet_script_src_obj = {
                baseQuery: baseQuery,
                zip: zip,
                clicksnet_credit_rating: clicksnet_credit_rating,
                clicksnet_loan_range: clicksnet_loan_range,
                clicksnet_loan_type_term: clicksnet_loan_type_term
            };

            var clicksnet_callback = function () {
                (clicksnet_listing.innerHTML = stripAndExecuteScript(
                    clicksnet_listing_content.content
                )),
                'undefined' != typeof clicksnet_sub_widget &&
                    clicksnet_sub_widget.init_clicksnet_sw(clicksnet_script_src_obj),
                    clicksNetAddExpandButtonListeners();
            };

            var clicksnet_listing_count = clicksnet_listing_count || 0;
            clicksnet_listing_count += 1;
            var clicksnet_listing_id = 'clicksnet_listing_' + clicksnet_listing_count;
            document.getElementById('confirmation_clicksnet')
                .innerHTML = '<div id="' + clicksnet_listing_id + '" style="margin-top: 25px;"></div>';
            var clicksnet_script = document.createElement('script');
            (clicksnet_script.type = 'text/javascript'),
            (clicksnet_script.async = !0),
            (clicksnet_script.src = clicksnet_script_src + '&gh=true&ghs=true'),
            clicksnet_script.addEventListener ?
                clicksnet_script.addEventListener('load', clicksnet_callback, !1) :
                clicksnet_script.readyState &&
                (clicksnet_script.onreadystatechange = function () {
                    ('loaded' == clicksnet_script.readyState ||
                        'complete' == clicksnet_script.readyState) &&
                    clicksnet_callback();
                });
            var clicksnet_listing = document.getElementById(clicksnet_listing_id);
            clicksnet_listing.appendChild(clicksnet_script);
        };
        
        $('#confirmationRT').hide();
        $('#confirmation_qs')
            .show();

        fetchListings();
        // if the randomized number is 91-100 show the renuant click wall
    } else {
        // State in renuantStates
        /*
        RENUANT CLICK WALL
         */
        console.log('rcw');
        $('#scrollbox')
            .remove();

        var mapPropertyType = function (type) {
            switch (type) {
            case "Single Family":
            default:
                return "1";
            case "Multi-Family Dwelling Unit":
                return "3";
            case "Condominium":
                return "2";
            case "Mobile Home":
                return "4";
            }
        };

        var mapCreditStatus = function (credit) {
            switch (credit) {
            case "Excellent":
                return "1";
            case "Good":
                return "3";
            case "Fair":
                return "4";
            case "Poor":
                return "5";
            case "":
                return "";
            }
        };

        var mapMilitary = function (military) {
            var mapped = military == 'Y' ? 1 : 2;
            return mapped;
        };

        var mapLoanAmount = function (loan) {
            switch (loan) {
            case "59900":
            case "75000":
            case "90000":
                return "2";
            case "105000":
            case "120000":
            case "135000":
            case "150000":
            default:
                return "3";
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
                return "11";
            case "555000":
            case "570000":
            case "585000":
                return "12";
            case "600000":
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
                return "15";
            case "750000":
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var mapPropertyValue = function (value) {
            switch (value) {
            case "90000":
                return "2";
            case "105000":
            default:
                return "3";
            case "120000":
            case "135000":
            case "150000":
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
            case "555000":
                return "11";
            case "570000":
            case "585000":
            case "600000":
                return "12";
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
            case "750000":
                return "15";
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var renData = {
            state: rt_data.state,
            pubcampaignid: "5529",
            loantype: "refinance",
            propertytype: mapPropertyType(rt_data.property_type),
            PropertyUse: "1",
            creditscore: mapCreditStatus(rt_data.credit_status),
            militaryservice: mapMilitary(rt_data.military),
            foreclosure: "2",
            interestrate: "4.35",
            loanbalance: mapLoanAmount(rt_data.loan_amt),
            propertyvalue: mapPropertyValue(rt_data.property_value),
            mS1: "SLS",
            mS2: rt_data.publisher_id,
            mS3: rt_data.subid
        };
        // postscribe('#confirmation_sh', '<script src="https://api.renuant.com/search/api/Ads/Loader"></script>');
        // Renuant.Render(data);
        confirmation_div.removeClass('collapse');
        confirmation_div.show();
        var iframe = document.createElement("iframe"); // Create a <iframe> node
        iframe.setAttribute('id', 'renuant-conf');
        var rate_table_source = "https://api.renuant.com/search/blue/green/Render?state=" + renData.state + "&pubcampaignid=" + renData.pubcampaignid + "&loantype=" + renData.loantype + "&propertytype=" + renData.propertytype + "&PropertyUse=" + renData.PropertyUse + "&creditscore=" + renData.creditscore + "&militaryservice=" + renData.militaryservice + "&foreclosure=" + renData.foreclosure + "&interestrate=" + renData.interestrate + "&loanbalance=" + renData.loanbalance + "&propertyvalue=" + renData.propertyvalue + "&template_name=basic&mS1=" + renData.mS1 + "&mS2=" + renData.mS2 + "&mS3=" + renData.mS3;
        // console.log(rate_table_source);
        iframe.src = rate_table_source;
        iframe.style.cssText = "width:100%";
        iframe.frameborder = 0;
        iframe.scrolling = "no";
        document.getElementById("confirmationRT")
            .appendChild(iframe);
    }
}

function getRandomNum(num) {
    return Math.floor(Math.random() * Math.floor(num)) + 1;
}


function hidePopUp() {
    $('html')
        .removeClass('popup-open');
    $('.popup')
        .fadeOut();
}
/*
    Generate a TrustedForm Certificate
 */
$('input')
    .on('input', function () {
        this.setAttribute("value", $(this)
            .val());
    });

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript';
tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date()
    .getTime() + Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);
/*
    LEAD ID
 */
// var s = document.createElement('script');
// s.id = 'LeadiDscript_campaign';
// s.type = 'text/javascript';
// s.async = true;
// s.src = (document.location.protocol + '//d1tprjo2w7krrh.cloudfront.net/campaign/AE2ADF9C-F349-3FD2-FFD9-AA7FF58165FD.js');
// var LeadiDscript = document.getElementById('LeadiDscript');
// LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if (typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]')
        .val(params.publisher_id);
} else {
    $('input[name="publisher_id"]')
        .val(632);
}
// subid
if (typeof params.subid !== "undefined") {
    $('input[name="subid"]')
        .val(params.subid);
} else {
    $('input[name="subid"]')
        .val('');
}
// rcid
if (typeof params.rcid !== "undefined") {
    $('input[name="rcid"]')
        .val(params.rcid);
} else {
    $('input[name="rcid"]')
        .val('');
}

/*
    HID
 */

$.ajax({
    url: '//' + config.endpoint + '/api/hdi/?upload_type=' + $('input[name="upload_type"]')
        .val() + '&publisher_id=' + $('input[name="publisher_id"]')
        .val(),
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]')
            .val(response.hid);
    }
    
});



/*
    ZIP CODE
 */
$('.start-form button')
    .click(function (e) {
        e.preventDefault();
        var $el = $(this)
            .prev('input');        
        var $button = $(this);
        var value = $el.val();
        var $form = $el.parent('form'); 
        $form.find('.error')
            .fadeOut();
        $el.removeClass('input-error');

        if (!$button.is(":disabled")) {
            $button.prop('disabled', true);
            // if (value >= 6001 && value <= 6928) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Connecticut consumers')
            //         .fadeIn();
            // // Florida Section
            // } else if (value >= 32003 && value <= 34997) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Florida consumers')
            //         .fadeIn();
            // Massachusetts Section
            if (value == "") {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Please enter a valid zip code.')
                    .fadeIn();
            } else if (value > 1000 && value <= 2791) {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Not accepting Massachusetts consumers')
                    .fadeIn();
            } else if (value == 5501) {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Not accepting Massachusetts consumers')
                    .fadeIn();
            }
            // // Michigan Section
            // } else if (value > 48000 && value < 49972) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Michigan consumers')
            //         .fadeIn();
            // Mississippi Section
            else if (value >= 38601 && value <= 39776) {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Not accepting Mississippi consumers')
                    .fadeIn();
            }
            // // North Carolina Section
            // } else if (value >= 27006 && value <= 28909) {
            // $el.addClass('input-error');
            // $form.find('.error')
            //     .text('Not accepting North Carolina consumers')
            //     .fadeIn();
            // // Ohio Section
            // } else if (value >= 43001 && value <= 45999) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Ohio consumers')
            //         .fadeIn();
            // // Oregon Section
            // } else if (value >= 97001 && value <= 97920) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Oregon consumers')
            //         .fadeIn();
            // Rhode Island Section
            // else if (value >= 2801 && value <= 2940) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Rhode Island consumers')
            //         .fadeIn();
            // }
            // Iowa Section
            // else if (value > 50000 && value < 52810) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Iowa consumers')
            //         .fadeIn();
            // }
            // // Virginia Section
            // } else if (value > 20100 && value <= 20199) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Virginia consumers')
            //         .fadeIn();
            // } else if (value >= 22003 && value <= 26886) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Not accepting Virginia consumers')
            //         .fadeIn();
            // } else if (value === "" || !isValidZipCode(value)) {
            //     $el.addClass('input-error');
            //     $form.find('.error')
            //         .text('Please enter a valid ZIP code')
            //         .fadeIn();
            else {
                getCity(value, $form.attr('id'));
            }
            
            $button.prop('disabled', false);
        }
    });

/*
 * Get city name
 */
var zip_response = null;

function getCity(value, id='#zipForm') {
    // Check zip code
    
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: { 'zip': value },
        success: function (response) {
            zip_response = response;
            zip_response.zip = value;

            $('input[name="zip"]')
                .val(value);
            $('input[name="city"]')
                .val(response.city);
            $('input[name="state"]')
                .val(response.state);

            $('.street-info')
                .text(response.city + ', ' + response.state + ', ' + value);
            $('.city-title-popup')
                .text(response.city);

            ls('zip', value);
            ls('city', response.city);
            ls('state', response.state);

            if (response.city) {
                showPopUp();
            } else {
                
                $('#' + id+' input[name=zip_code]')
                    .addClass('input-error');
                $('#'+id)
                    .find('.error')
                    .text('Please enter a valid ZIP code')
                    .fadeIn();
            }
        }
        
    });
}

$(document)
    .on('change', '.radio-list:visible input', function () {
        $('.radio-list:visible li')
            .removeClass('active');
        $(this)
            .parent('label')
            .parent('li')
            .addClass('active');
    });

var step_2 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_1"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please Select Home Type</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_3 = {
    select: function () {
        // Init custom selects
        $('#property-value')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //    $('input[name="provider-radio"]').prop('checked', false);
                    //   $('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        //$(document).on('change', '#property-value', function () {
        //var val = $('#property-value option:selected').val();
        //$('input[name="property-value"]').prop('checked', false);
        //$('input[name="field_2"]').val(val);
        //});

        $('#mortgage-balance')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //$('input[name="mortgage-balance"]').prop('checked', false);
                    //$('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        $(document)
            .on('change', 'mortgage-balance', function () {
                //var val = $('#property-value option:selected').val();
                //$('input[name="mortgage-balance"]').prop('checked', false);
                //$('input[name="field_2"]').val(val);
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected')
                .length > 0);
            var is_mort_selected = ($('#mortgage-balance option:selected')
                .length > 0);
            if (!is_prop_selected && !is_mort_selected) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select estimated property value and estimated mortgage balance</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (!is_prop_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated property value</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (!is_mort_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated mortgage balance</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
            }
            $button.prop('disabled', false);
        }
    }
};

step_3.select();

var step_4 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_6"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select how you would rate your credit</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_5 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="cash_out"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if you would like to take cash out</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_6 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_34"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select your employment status</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_7 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_23"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if your mortgage is FHA</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_8 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_28"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if you are a veteran or active military</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

$('input[name=first_name], input[name=last_name]').on('keyup', function(e){
    
    if (!e.target.value.match(/^[A-Za-z]+$/)) {
        let val = e.target.value.match(/[A-Za-z]+/g);
        if (val) {
            $(this).val(val.join(''));
        } else {
            $(this).val('');
        }                
    }
});

var step_9 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]')
                .val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]')
                .val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]')
                .val();
            email = $.trim(email);

            if (first_name === "" && last_name === "" && email === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your First Name, Last Name and Email</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (email === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                } else {
                    if (!validEmail(email)) {
                        $('.step-form .step.active .errors')
                            .append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active')
                            .addClass('has-errors');
                    }
                }
            }
            $button.prop('disabled', false);
        }
    }
};

var step_10 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]')
                .val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');
            // Adress
            var street = $('.step-form .step.active input[name="street_address"]')
                .val();
            street = $.trim(street);

            if (phone === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Phone Number</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else if (phone !== "" && phone.length < 10) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            if (phone.startsWith("1") == true) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please Don\'t Begin Phone Number with a 1</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            if (street === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Street Address</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {
    setHash('#' + step);
    $('.step-form .step')
        .removeClass('active');
    $('.step-form .step[data-step="' + step + '"]')
        .addClass('active');
        console.log('.step-form .step[data-step="' + step + '"]');
    $('.form-top .steps .current')
        .text(step);
    //$('.step-form .step.active input').first().focus();
    switch (step) {
    case 3:
        //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
        break;
    case 4:
        //set Loan Amount value to first mortgage balance value
        var f4value = $("[name = field_4]")
            .val();
        $("[name = field_3]")
            .val(f4value);
        break;
    case 6:
        // Add input mask
        var selector = document.getElementById("home_phone");
        var im = new Inputmask("(999) 999-9999");
        im.mask(selector);
        break;
    }
}

$(document)
    .on('click', '.step-back', function (e) {
        e.preventDefault();
        var step = $('.step-form .step.active')
            .data('step') - 1;
        console.log("Step"+step);
        if (step > 1) {
            changeStep(step);
        } else {
            // hide popup
            hidePopUp();
        }
    });

$(document)
    .on('submit', 'form[name="leadform"]', function (e) {
        e.preventDefault();
    });

$(document)
    .on('click', '.step-button:visible', function (e) {
        e.preventDefault();
        var step = $(this)
            .data('step');
        if (step === 'submit') {
            var anura_json = sessionStorage.getItem('anura_final_response');

            step_10.validate();
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                convert_fire = true;
                window._conv_q = window._conv_q = [];
                _conv_q.push(['recheck_goals']);

                //submit
                $('[name=anura]')
                    .val(anura_json);
                $('[name=referrer]')
                    .val(document.referrer);
                $('[name=useragent]')
                    .val(window.navigator.userAgent);
                var formData = $('#leadform')
                    .serialize();

            //     if (typeof _webpushTrackAttributes == 'function') { 
            //   console.log("call push pros: true");

            //   _webpushTrackAttributes({
            //     "aff_id": $('[name=publisher_id]').val(),
            //     "military": $('[name=field_28]').val(),
            //     "first_name": $('[name=first_name]').val(),
            //     "last_name": $('[name=last_name]').val(),
            //     "state": $('[name=state]').val(),
            //     "zip_code": $('[name=zip_code]').val(),
            //     "home_type": $('[name=field_1]').val(),
            //     "property_value": $('[name=field_2]').val(),
            //     "mortgage_balance": $('[name=field_4]').val(),
            //     "cash_out": $('[name=cash_out]').val(),
            //     "credit_status": $('[name=field_6]').val()
            // });
            // } else {
            //     console.log("call push pros: false");
            // }
                
                // Ajax call to post data
                
                $.ajax({
                    url: post_url,
                    dataType: 'json',
                    method: 'post',
                    data: formData,
                    success: function (results) {
                        console.log(results);
                        window.location.hash = "thankyou";
                        if (results.status == 'success') {
                            if ($('input[name="publisher_id"]')
                                .val() == '1110') {
                                var ltv_1110 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                var house_1110 = $('select[name="field_2"]')
                                    .val();
                                var loan_1110 = $('select[name="field_4"]')
                                    .val();
                                var credit_1110 = $('input[name="field_6"]')
                                    .val();
                                var tier1_credits_1110 = ['Excellent', 'Good'];
                                var pub1110BadStates = ['AZ', 'OH', 'MA', 'NY'];

                                if (ltv_1110 <= 0.95 &&
                                    loan_1110 >= 100000 &&
                                    house_1110 >= 100000 &&
                                    tier1_credits_1110.indexOf(credit_1110) != -1 &&
                                    pub1110BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var ifrm1110 = document.createElement('iframe');
                                    ifrm1110.setAttribute('scrolling', 'no');
                                    ifrm1110.setAttribute('frameborder', '0');
                                    ifrm1110.setAttribute('width', '1');
                                    ifrm1110.setAttribute('height', '1');
                                    ifrm1110.setAttribute('src', 'https://provenpartners.go2cloud.org/aff_l?offer_id=4');
                                    document.body.appendChild(ifrm1110); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]')
                                .val() == '1161') {
                                console.log("I'm in the logic block!");
                                var ltv_1161 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                var house_1161 = $('select[name="field_2"]')
                                    .val();
                                var loan_1161 = $('select[name="field_4"]')
                                    .val();
                                var credit_1161 = $('input[name="field_6"]')
                                    .val();
                                var tier1_credits_1161 = ['Excellent', 'Good'];
                                var pub1161BadStates = ['AZ', 'OH', 'NY'];

                                console.log(ltv_1161);

                                if (ltv_1161 <= 0.95 &&
                                    loan_1161 >= 100000 &&
                                    house_1161 >= 100000 &&
                                    tier1_credits_1161.indexOf(credit_1161) != -1 &&
                                    pub1161BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    console.log(lead_id);
                                    var pixel_1161 = document.createElement('img');
                                    pixel_1161.setAttribute('width', '1');
                                    pixel_1161.setAttribute('height', '1');
                                    pixel_1161.setAttribute('border', '0');
                                    pixel_1161.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=357&e=7&f=img&t=' + lead_id);
                                    document.body.appendChild(pixel_1161);
                                }
                            }
                        }
                    }
                    
                });
                
                getClickWall();
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();

            }
        } else {
            switch (step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
            case 9:
                step_9.validate();
                break;
            case 10:
                step_10.validate();
                break;
            }
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                changeStep(step);
            }
        }
    });

// Tooltip
$(document)
    .on('click', 'a.tooltip', function (e) {
        e.preventDefault();
    });

// Use for the GeoIP2 JavaScript API is to fill state name
var fillState = (function () {
    var getStateName = function (geoipResponse) {
        /*
            most_specific_subdivision returns the smallest available
            subdivision (region) as defined in ISO 3166-2.
        */
        var excludedStates = [
            // "CONNECTICUT",
            // "FLORIDA",
            // "IOWA",
            "MASSACHUSETTS",
            // "MICHIGAN",
            "MISSISSIPPI",
            // "NORTH CAROLINA",
            // "OHIO",
            // "OREGON",
            // "RHODE ISLAND",
            // "VIRGINIA"
        ];
        var regionName = geoipResponse.most_specific_subdivision.names.en || '';
        var regionCheck = regionName.toUpperCase();
        if (regionName) {
            if(excludedStates.indexOf(regionCheck) == -1) {
                document.getElementById("header-state-text")
                    .innerHTML = regionName + ' ';
            } else {
                document.getElementById("header-state").innerHTML = 'Sorry, we are currently not accepting ' + regionName + ' consumers';
                document.getElementById("subheader").innerHTML = '';
                document.getElementById("zipField").setAttribute("disabled", true);
                document.getElementById("zipField").setAttribute("style", "display: none;");
                document.getElementById("zip-button").setAttribute("disabled", true);
                document.getElementById("zip-button").setAttribute("style", "display: none;");
                document.getElementById("challenge_text").setAttribute("style", "display: none;");
            }
        }
    };
    var onSuccess = function (geoipResponse) {
        getStateName(geoipResponse);
    };
    /* If we get an error we will do nothing*/
    var onError = function (error) {
        return;
    };
    return function () {
        geoip2.city(onSuccess, onError);
    };
}());

//Fill current date
var dt = new Date();
var month = dt.toDateString()
    .split(' ')[1];
var day = dt.toDateString()
    .split(' ')[2];
var year = dt.toDateString()
    .split(' ')[3];
var currentDate = month + ' ' + day + ', ' + year;
document.getElementById("date")
    .innerHTML = currentDate;

var allowedHashes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];



$(document)
    .ready(function () {

        /*
         * Generate a TrustedForm Certificate
         */
        $('input')
            .on('input', function () {
                this.setAttribute('value', $(this)
                    .val());
            });
        (function () {
            var field = 'TrustedFormToken';
            var provideReferrer = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                '://api.trustedform.com/trustedform.js?provide_referrer=' +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date()
                .getTime() +
                Math.random();
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tf, s);
        })();
        /*
              LEAD ID
           */


        $('input[name=zip_code]')
            .val("");
        $(window)
            .keydown(function (e) {
                if ($('input[name=zip_code]')
                    .index($(e.target)) != -1) {
                    if (
                        // digits, digits in num pad, 'back', 'enter', '.', '-'
                        ($.inArray(e.keyCode, [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 13, 190, 189]) == -1) ||
                        // not allow double dot '.'
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .indexOf(".") != -1) ||
                        // not allow dot '.' at the begining    
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .length == 0)
                    ) {
                        e.preventDefault();
                    } else {
                        if ($(e.target)
                            .val()
                            .length > 4 && e.keyCode !== 8) {
                            e.preventDefault();
                        }
                    }
                }
            });

        var hashValue = location.hash;
        hashValue = hashValue.replace(/^#/, '');

        if (isInArray(hashValue, allowedHashes)) {
            if (ls('zip')) {
                getCity(ls('zip'));
                setTimeout(function () {
                    showPopUp(false);
                    changeStep(hashValue);
                }, 200);
            }
            setTimeout(function () {
                $('html')
                    .addClass('ready');
            }, 300);
        } else {
            $('html')
                .addClass('ready');
        }

        fillState();

        if (
            typeof params.show_confirmation !== 'undefined' &&
            (
                params.show_confirmation === 'true' ||
                params.show_confirmation === '1'
            )
        ) {
            showPopUp();
            $('.popup')
                .removeClass('hide');
            $('.step.active')
                .removeClass('active');
            $('.step.step-confirmation')
                .addClass('active');
            $('.form-top .steps')
                .empty();
            var exit_strategy = true;
            getClickWall(exit_strategy);

        } else if (
            typeof params.optout !== 'undefined' &&
            (
                params.optout === 'true' ||
                params.optout === '1'
            )
        ) {
            $('#opt-out-link')
                .click();
        } else if (
            typeof params.privacy !== 'undefined' &&
            (
                params.privacy === 'true' ||
                params.privacy === '1'
            )
        ) {
            $('#privacy-link')
                .click();
        } else if (
            typeof params.terms !== 'undefined' &&
            (
                params.terms === 'true' ||
                params.terms === '1'
            )
        ) {
            $('#terms-link')
                .click();
        }
    });